.container {
    padding: 0 40px;
    margin-top: 30px;
    height: 100%;
} 

.container .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container .title button {
    padding: 8px 20px;
    margin-left: 15px;
    border-radius: 10px;
    border: 3px solid #DFDFDF;
    background-color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
    font-family: 'Almarai', sans-serif;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
}

.container .title .main-title {
    font-size: 60px !important;
    font-weight: 700;
}

.table-icon {
    display: flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #240259;
    color: white;
}

.table-image {
    display: flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    color: white;
}

.iconTable {
    font-size: 15px !important;
    color: white !important;
    position: relative;
    cursor: pointer;
}

.header-cell {
    font-size: 20px;
    font-family: 'Almarai', sans-serif !important;
}

.odd {
    background-color: #eee;
    font-family: 'Almarai', sans-serif !important;
}

.even {
    font-family: 'Almarai', sans-serif !important;
}

.chapter {
    flex: 1;
    height: 200px;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    border: 3px solid #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Almarai', sans-serif !important;
    font-size: 40px;
    cursor: pointer;
    transition: all 0.4s ease;
}
.chapter:hover {
    background-color: #240259;
    color: white;
}
.chapter-active {
    flex: 1;
    height: 200px;
    background-color: #240259;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    border: 3px solid #DFDFDF;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Almarai', sans-serif !important;
    font-size: 40px;
    cursor: pointer;
    color: white;
    transition: all 0.4s ease;
}

