.sidebar {
  width: 365px;
  height: calc(100vh - 20px);
  background-color: #ffffff;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 27px 27px 0 0;
  border: 5px solid #dfdfdf;
  margin-top: 15px;
  margin-right: 20px;
  padding-top: 50px;
  overflow-y: auto;
  border-bottom: none;
}

.sidebar::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}

.contaner-circle {
  margin-bottom: 20px;
  text-align: center;
}
.title {
  color: #240259;
  font-size: 30px;
  font-weight: 700;
}

.circle {
  width: 120px;
  height: 120px;
  background-color: #9e9e9e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.link {
  color: #240259;
  padding: 0 25px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  text-decoration: none;
  border: 4px solid #240259;
  width: 100%;
  margin: 8px 0;
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
}
.link:hover {
  background-color: #240259;
  color: white;
}

.link:hover div{
  background-color: white;
  color: #240259;
}

.active {
  padding: 0 25px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 47px;
  text-decoration: none;
  border: 4px solid #240259;
  width: 100%;
  margin: 8px 0;
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.2);
  background-color: #240259 !important;
  color: white !important;
}

.active-icon {
  background-color: white !important;
  color: #240259 !important;
  display: flex;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.link .container-icon {
    display: flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #240259;
    color: white;
}

.icon {
    font-size: 15px !important;
    position: relative;
    left: -1px;
    cursor: pointer;
}



/*logo style */
.logo-container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  margin-bottom: 10px;
}

.logo-container2 .ellipse1 {
  width: 180px;
  height: 180px;
  background-image: url('../constant/images/Ellipse\ 185.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container2 .ellipse2 {
  width: 120px;
  height: 120px;
  background-image: url('../constant/images/Ellipse\ 184.png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container2 .ellipse2 .groub1 {
  width: 75px;
  height: 75px;
  background-image: url('../constant/images/Vector\ \(1\).png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px #b3a7a7;
  border-radius: 20px;
}

.logo-container2 .ellipse2 .groub1 .groub2{
  width: 75px;
  height: 75px;
  background-image: url('../constant/images/Vector.png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container2 .ellipse2 .groub1 .groub2 .vector1 {
  width: 70px;
  height: 70px;
  background-image: url('../constant/images/Group\ \(1\).png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container2 .ellipse2 .groub1 .groub2 .vector1 .vector2 {
  width: 35px;
  height: 65px;
  background-image: url('../constant/images/Group.png');
  background-size: cover; 
}