.main {
  width: 100%;
  height: 100vh;
  background-color: #240259;
  background-image: url('../constant/images/\(O\).png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 210px;
}

.logo-container .ellipse1 {
  width: 220px;
  height: 220px;
  background-image: url('../constant/images/Ellipse\ 185.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container .ellipse2 {
  width: 150px;
  height: 150px;
  background-image: url('../constant/images/Ellipse\ 184.png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container .ellipse2 .groub1 {
  width: 85px;
  height: 85px;
  background-image: url('../constant/images/Vector\ \(1\).png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px #b3a7a7;
  border-radius: 20px;
}

.logo-container .ellipse2 .groub1 .groub2{
  width: 85px;
  height: 85px;
  background-image: url('../constant/images/Vector.png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container .ellipse2 .groub1 .groub2 .vector1 {
  width: 80px;
  height: 80px;
  background-image: url('../constant/images/Group\ \(1\).png');
  background-size: cover; 
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-container .ellipse2 .groub1 .groub2 .vector1 .vector2 {
  width: 35px;
  height: 65px;
  background-image: url('../constant/images/Group.png');
  background-size: cover; 
}

.con-form {
  width: 360px;
  min-height: 500px;
  background-color: white;
  border: 5px solid #dfdfdf;
  border-radius: 15px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.con-form form {
  width: 100%;
  padding: 20px 0 0 0;
  text-align: center;
}
.con-form form div {
  /* margin-top: 5px; */
}

.con-form form label {
  color: #240259;
  font-weight: 700;
}

.con-form form div input {
  width: 280px;
  outline: none;
  border: 3px solid #240259;
  border-radius: 15px;
}

.input-field {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 15px;
}

/* To style the input when it's focused */
.input-field:focus {
  border-color: #6b7fff;
  outline: none;
  box-shadow: 0 0 5px rgba(107, 127, 255, 0.5);
}

.button {
  background-color: #240259;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.button:hover {
  background-color: #607d8b;
}
