.center {
  position: relative;
  padding: 20px 50px 0 50px;
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 555px;
  justify-content: center;
  align-items: center;
}

.center::-webkit-scrollbar {
  display: none; /* Hide the scrollbar in WebKit browsers (Chrome, Safari, etc.) */
}

.input-contener {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 20px;
}

.sort-contener {
  width: 350px;
  background-color: #eeeeee;
  margin-bottom: 15px;
  border-radius: 15px;
  border: 2px solid #240259;
}
.sort-contener p {
  padding: 10px 15px;
  text-align: right;
  font-family: "Almarai", sans-serif;
  font-size: 16px;
  margin: 0;
}

.btn {
  border-radius: 8px;
  border: 3px solid #dfdfdf;
  background-color: #ffffff;
  padding: 4px 10px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
  font-family: "Almarai", sans-serif;
  cursor: pointer;
}

.add-btn {
  border-radius: 8px;
  border: 3px solid #240259;
  background-color: #ffffff;
  padding: 4px 10px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
  font-family: "Almarai", sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
}
.add-btn:hover {
  background-color: #240259;
  color: white;
}

.add-active {
  border-radius: 8px;
  border: 3px solid #240259;
  background-color: #240259;
  color: white;
  padding: 4px 10px;
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
  font-family: "Almarai", sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
}

.center .inputbox {
  position: relative;
  width: 350px;
  height: 50px;
  margin-bottom: 45px;
}

.center .inputbox input,
select, textarea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 4px solid #240259;
  outline: none;
  background: none;
  font-family: "Almarai", sans-serif;
  padding: 10px;
  border-radius: 10px;
  font-size: 20px;
  color: #240259;
  font-weight: 700;
}

.material {
  width: 350px;
  /* outline: none;
    border: 4px solid #240259;
    border-radius: 15px; */
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center .inputbox:last-child {
  /* margin-bottom: 0; */
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.center .inputbox span {
  position: absolute;
  top: 15px;
  left: 270px;
  font-size: 20px;
  transition: 0.6s;
  color: #240259;
  font-weight: 700;
  font-family: "Almarai", sans-serif;
}

.center .inputbox input:focus ~ span,
.center .inputbox input:valid ~ span {
  transform: translateX(5px) translateY(-45px);
  font-size: 17px;
  color: black;
}

.center .inputbox button {
  padding: 7px 5px;
  width: 100px;
  background-color: white;
  color: #240259;
  border: 5px solid #240259;
  border-radius: 15px;
  font-family: "Almarai", sans-serif;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s ease;
}
.center button:hover {
  background-color: #240259;
  color: white;
}

.center button:hover .hoverhover {
  color: white !important;
}

option {
  padding: 20px;
}

.checkbox-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 15px;
  margin-bottom: 25px;
}

.checkbox-child1 {
  display: flex;
  width: 300px;
  justify-content: space-between;
  align-items: center;

}

.checkbox-child1 div {
  color: #240259;
}

.checkbox-child1 div input {
  margin: 0 0 0 5px;
  width: 15px;
  height: 15px;
  accent-color: #240259;
}

